<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <b-card>
          <b-col>
            <h1 class="text-welcome-header">
              Marketplace
            </h1>
            <h2 class="text-welcome-subheader">
              {{ vendor == null ? $t('Shop') : vendor }}
            </h2>
          </b-col>
        </b-card>
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <data-statistics-vendor />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row
          v-if="vendor"
          class="mb-5"
        >
          <b-col>
            <h1>{{ $t('Orders') }}</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <component :is="vendor ? 'OrdersElement' : 'Payments'" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'

import store from '@/store'
import { getUserData } from '@/auth/utils'
import Payments from '@/views/shop/payments/Payments.vue'
import OrdersElement from '@/views/vendor/orders/OrdersElement.vue'

const DataStatisticsVendor = () => import('../DataStatisticsVendor.vue')

const Search = () => import('@/views/contents/search/search.vue')
export default {
  components: {
    BRow,
    BCol,
    DataStatisticsVendor,
    BCard,
    Payments,
    BButton,
    OrdersElement,
    Search,
  },
  data() {
    return {
      roles: null,
      data: {},
      vendor: '',
    }
  },
  created() {
    const userData = getUserData()
    try {
      this.vendor = userData.profile.profileVendor.edges[0].node.vendorName
    } catch (error) {
      this.vendor = null
    }
    const query = `{
      client(id: "${userData.profile.client.id}") {
        id
        name
        totalVideos
        totalProducts
        totalInteractivity
        contentInteractivityLimit
        initialCatLimit
        nestedCatLimit
        initialCatProductLimit
        nestedCatProductLimit
        productsCatLimit
      }
    }`
    axios.post('', { query }).then(response => {
      messageError(response, this)
      const data = response.data.data.client
      const platformConfig = {
        initialCategoryLimit: data.initialCatLimit,
        nestedCategoryLimit: data.nestedCatLimit,
        totalContentsLimit: data.totalVideos,
        totalProductsLimit: data.totalProducts,
        totalInteractivityLimit: data.totalInteractivity,
        contentInteractivityLimit: data.contentInteractivityLimit,
      }
      store.commit('app/SET_PLATFORM_CONFIGURATION', platformConfig)
    })
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
